import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://8f3ecd23b71679beff9d2fbe8439937d@o4506507546525696.ingest.us.sentry.io/4508083674021888",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  integrations: [],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});